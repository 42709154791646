import store from "@/store/index";

//comentado temporariamente
const DocMenuConfig = [
  {
    route: "/",
    pages: 
    [
      
      {
        heading: "Dashboard",
        route: "/dashboard",
        nivelCargo: [100, 150, 200, 300, 400, 650],
        svgIcon: "./media/icons/duotune/graphs/gra008.svg",
      },
      {
        heading: "Lojas",
        route: "/lojas",
        nivelCargo: [100, 150],
        fontIcon: "fa-store",
        svgIcon: "./media/icons/duotune/general/store1.svg",
      },
      {
        heading: "Usuários",
        route: "/usuarios",
        nivelCargo: [100, 150],
        svgIcon: "./media/icons/duotune/general/tools-duotone.svg",
      },
      {
        heading: "Atendimentos",
        route: "/lojas/atendimentos?",
        nivelCargo: [100, 150],
        fontIcon: "fa-chart-area",
        svgIcon: "./media/icons/duotune/general/hands.svg",
      },
      {
        sectionTitle: "Cadastros",
        nivelCargo: [100, 150],
        svgIcon: "./media/icons/duotune/general/box.svg",
        sub: [
          {
            heading: "Grupos",
            route: "/cadastros/grupos",
            nivelCargo: [100, 150],
          },
          {
            heading: "Bandeiras",
            route: "/bandeiras",
            nivelCargo: [100, 150],
          },
          {
            heading: "Lubrificantes",
            route: "/gestaoLubrificantes",
            nivelCargo: [100, 150],
          },
          {
            heading: "Fracionados",
            route: "/produtosFracionados",
            nivelCargo: [100, 150],
          },
          {
            heading: "Planos",
            route: "/planos",
            nivelCargo: [100, 150],
          },
          {
            heading: "Acompanhamento Implantação",
            route: "/acompanhamentoImplantacao",
            nivelCargo: [100, 150],
          }
        ],
      },
      {
        sectionTitle: "Gestão Técnica",
        nivelCargo: [100, 150],
        svgIcon: "./media/icons/duotune/general/cogs.svg",
        sub: [
          {
            heading: "Veículos",
            route: "/filtroVeiculos",
            nivelCargo: [100, 150],
          },
          {
            heading: "Filtros",
            route: "/veiculos",
            nivelCargo: [100, 150],
          },
          {
            heading: "Revisão de Veículos",
            route: "/veiculosRevisao",
            nivelCargo: [100, 150],
          },
          {
            heading: "Upload Filtros",
            route: "/planilhas",
            nivelCargo: [100, 150],
          },
          {
            heading: "Upload Veículos",
            route: "/planilhasVeiculos",
            nivelCargo: [100, 150],
          },
          {
            heading: "Upload Lubrificante Transmissão",
            route: "/uploadLubrificanteTransmissao",
            nivelCargo: [100, 150],
          },
        ]
      },
      {
        sectionTitle: "Gestão Ipiranga",
        nivelCargo: [100, 150],
        svgIcon: "./media/icons/duotune/general/cogs.svg",
        sub: [
          {
            heading: "Usuários Ipiranga",
            route: "/usuariosIpiranga",
            nivelCargo: [100, 150],
          },
          {
            heading: "Lojas Validação Jetoil",
            route: "/lojasValidacaoJetoil",
            nivelCargo: [100, 150],
          },
          {
            heading: "Produtos Validação Jetoil",
            route: "/produtosIpiranga",
            nivelCargo: [100, 150],
          },
          {
            heading: "Gestão Api Conecta",
            route: "/gestaoApiConecta",
            nivelCargo: [100, 150],
          },
        ]
      },
      {
        sectionTitle: "Relatórios",
        nivelCargo: [100, 150],
        svgIcon: "./media/icons/duotune/general/relatorio.svg",
        sub: [
          {
            heading: "Relatório Busca Placa",
            route: "/relatorios/buscaPlaca",
            nivelCargo: [100, 150],
          },
          {
            heading: "Visualização de custos",
            route: "/custosApp",
            nivelCargo: [100, 150],
          },
          {
            heading: "Visualização de uso do app",
            route: "/usoApp",
            nivelCargo: [100, 150],
          },
          {
            heading: "Relatório consultas",
            route: "/relatorios/consultas",
            nivelCargo: [100, 150],
          }
        ],
      },
      // {
      //   sectionTitle: "Relatórios JetOil",
      //   nivelCargo: [100, 150, 650],
      //   svgIcon: "./media/icons/duotune/general/relatorio.svg",
      //   sub: [
      //     {
      //       heading: "Relatório de retorno",
      //       route: "/relatorios/downloadRetornoConsumidor",
      //       nivelCargo: [650],
      //     },
      //   ],
      // },
      {
        sectionTitle: "Logs",
        nivelCargo: [100, 150],
        svgIcon: "./media/icons/duotune/general/gen005.svg",
        sub: [
          {
            heading: "Integração de Produtos",
            route: "/logs/produtos",
            nivelCargo: [100, 150],
          },
          {
            heading: "Integração Conecta",
            route: "/dashboard",
            nivelCargo: [100, 150],
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
