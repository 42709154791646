
import { defineComponent, ref, onMounted, computed, DefineComponent } from "vue";
import KTMenu from "@/layout/header/Menu.vue";
import KTMenuDropdown from "@/layout/header/MenuDropdown.vue";
import { useStore } from "vuex";
import {
  // headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import ModalFiltro from "./ModalFiltro.vue";
import { Modal } from "bootstrap";
import {useChangeContainer} from '@/store/ChangeContainer'

export default defineComponent({
  name: "KTHeader",
  components: {
    KTMenu,
    KTMenuDropdown,
  },
  setup() {
    const getUseChangeContainer = useChangeContainer()
    const store = useStore();
    const isMobile = ref(false);

    onMounted(() => {
      let userAgent = navigator.userAgent || navigator.vendor;
      if (userAgent.match(/Android/i) || userAgent.match(/iPhone/i)) {
        isMobile.value = true;
      } else {
        isMobile.value = false;
      }
    });

    return {
      headerTitle: "indicar",
      // headerWidthFluid,
      headerLeft,
      asideDisplay,
      isMobile,
      getUseChangeContainer
    };
  },
});
